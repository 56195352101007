<template>
    <v-container pa-10>
        <v-card max-width="1000" class="mx-auto my-5 pa-5">
            <v-col cols="12">
                <header :class="header_color(true)"><b>Q1.</b> <b>Click</b> on <u>the base of the cow's tail</u>.</header>
                <header :class="header_color(false)"><b>Q2.</b> <b>Click</b> on the image so that the arrow points in <u>the direction of the spine</u>.</header>
            </v-col>
            <v-row align="center" justify="center" class="mx-auto my-3">
                
                <img id="img" :height="img_height" contain :src="nano.props.img_url0" ref="cow_image">
                <canvas id="myCanvas" :height="canvas_height" :width="canvas_width" ref="canvas" contain v-on:mousemove="mousepos" 
                                                                                                   v-on:mouseleave="out_canvas"
                                                                                                   v-on:click="get_pos"></canvas>

            </v-row>
            <v-row class="mx-auto my-14">
                <input v-show=false v-nano.required v-model=get_pos_hip_X>
                <input v-show=false v-nano.required v-model=get_pos_hip_Y>
                <input v-show=false v-nano.required v-model=img_mag>
                <input v-show=false v-nano.required v-model=get_pos_dir_X>
                <input v-show=false v-nano.required v-model=get_pos_dir_Y>
                <input v-show=false v-nano.required v-model=ang>
                <v-col cols="3" align="left">
                    <v-btn class="ma-3" height=50 :disabled="!get_pos_flag" @click="get_init_info">Reset</v-btn>
                </v-col>
                <v-col cols="8" align="right">
                     <v-btn class="ma-3" height=50 :disabled="get_pos_flag" @click="unclear()">Unclear</v-btn>
                    <v-btn class="ma-3" height=50 :disabled="!get_dir_flag" @click=submit()>Next</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<style>
.light {
  color: rgba(0, 0, 0, 0.2);
}
.dark {
  color: rgba(0, 0, 0, 1);
}
    
#img {
 
 margin-top: 50px;
} 

#myCanvas {
 border: 1px solid #000000;
 position: absolute;
 margin-top: 50px;
}

</style>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "/static/crop_big_imgs/62324959878645-20191018140343_107_307_649_615.jpg",
            //"img_url0": "/static/crop_big_imgs/62324959878645-20191029080741_129_15_544_221.jpg",
            //"img_url0": "/static/crop_big_imgs/171611376995565-20190712115802_478_207_1067_958.jpg",
        },
        windowWidth: 200,
        windowHeight: 200,
        img_height: 400,
        img_width: 600,
        canvas_height: 400,
        canvas_width: 600,
        canvas_padding: 50,
        img_mag: 1,
        canvas: null,
        context: null,
        get_pos_flag: false,
        get_dir_flag: false,
        get_pos_hip_X: -1,
        get_pos_hip_Y: -1,
        get_pos_dir_X: -1,
        get_pos_dir_Y: -1,
        natural_width: 100,
        natural_height: 100,
        new_image: [],
        img: new Image(),
        
        //矢印の長方形部分
        rect_x: 60, 
        rect_y: 8, 
        //矢印の三角形部分
        tra_x: 25,
        tra_y:25, 
        //回転角度 (矢印の向き)
        ang: -1,
        //長方形の開始位置
        base_x: 100,
        base_y: 100,
    }),  

    mounted () {
        this.init()
        window.addEventListener('resize', this.init)
        //enterキーを押すことでも、データがあればsubmitできる
        window.addEventListener('keyup', (e) =>  {
            if (this.get_pos_dir_X != -1 && e.keyCode === 13) { 
                this.submit()
            }
        })
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.init);
    },

    methods: {
        init() {
            //windowサイズ取得
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;

            //元画像の大きさ取得
            this.img.onload = () => {
                this.new_image.push({width: this.img.width, height: this.img.height});
            }
            this.img.src = this.nano.props.img_url0;
        },

        get_init_info() {
            this.natural_width = this.img.width;
            this.natural_height = this.img.height;

            //倍率取得
            var img_mag_x = this.natural_width / (this.windowWidth-400);         
            var img_mag_y = this.natural_height / (this.windowHeight-400);
            if (img_mag_x > img_mag_y) {
               this.img_mag = img_mag_x;
            } else {
               this.img_mag = img_mag_y;
            }      
            this.img_width = parseInt(this.natural_width / this.img_mag);
            this.img_height = parseInt(this.natural_height / this.img_mag);
            this.canvas_width = this.img_width + this.canvas_padding*2;
            this.canvas_height = this.img_height + this.canvas_padding*2;
            this.canvas = this.$refs.canvas;
            this.context = this.canvas.getContext("2d");
            this.get_pos_flag = false;
            this.get_pos_hip_X = -1;
            this.get_pos_hip_Y = -1;
            this.get_pos_dir_X = -1;
            this.get_pos_dir_Y = -1;
            this.get_dir_flag =  false;
            this.ang = -1;
            this.base_x = parseInt(this.canvas_width/2)
            this.base_y = parseInt(this.canvas_height/2)
            let ctx = this.context.canvas.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, this.canvas_width, this.canvas_height)
        },

        mousepos(e) {
            let ctx = this.context.canvas.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, this.canvas_width, this.canvas_height)

            if (!this.get_pos_flag) {
                // hip
                ctx.arc(e.offsetX, e.offsetY, 15, 0, Math.PI * 2)
                ctx.fillStyle = "rgba(255,0,0,0.4)" ;
                ctx.fill()
                if (this.get_pos_hip_X!=-1) {
                    ctx.beginPath()
                    ctx.arc(this.get_pos_hip_X, this.get_pos_hip_Y, 15, 0, Math.PI * 2)
                    ctx.fillStyle = "rgba(255,0,0,1.0)" ;
                    ctx.fill()
                }
            }
            if (this.get_pos_flag) {
                // direction
                var x_ = parseInt(e.offsetX) - this.base_x;
                var y_ = parseInt(e.offsetY) - this.base_y;
                var rect_x_tmp = Math.sqrt(x_**2+y_**2)-this.tra_x;

                var rad_ = Math.atan(x_/y_);
                var ang_tmp = parseInt(-rad_/Math.PI*180);
                if (y_>=0) {
                    ang_tmp = ang_tmp + 180
                }
                if (ang_tmp<0) {
                    ang_tmp = ang_tmp + 360
                }
                this.draw_arrow(0.4, ang_tmp, rect_x_tmp)
                if (this.ang != -1) {
                    this.draw_arrow(1.0, this.ang, this.rect_x)
                }
            }
        },
        out_canvas() {
            let ctx = this.context.canvas.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, this.canvas_width, this.canvas_height)
            if (this.get_pos_hip_X!=-1) {
                ctx.beginPath()
                ctx.arc(this.get_pos_hip_X, this.get_pos_hip_Y, 15, 0, Math.PI * 2)
                ctx.fillStyle = "rgba(255,0,0,1.0)";
                ctx.fill()
            }
            if (this.ang!=-1) {
                this.draw_arrow(1.0, this.ang, this.rect_x)
            }
        },
        get_pos(e) {
            let ctx = this.context.canvas.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, this.canvas_width, this.canvas_height)
            if (!this.get_pos_flag) {
                // hip
                this.get_pos_hip_X = parseInt(e.offsetX);
                this.get_pos_hip_Y = parseInt(e.offsetY);
                this.base_x = this.get_pos_hip_X
                this.base_y = this.get_pos_hip_Y
                ctx.arc(this.get_pos_hip_X, this.get_pos_hip_Y, 15, 0, Math.PI * 2)
                ctx.fillStyle = "rgba(255,0,0,1.0)" ;
                ctx.fill()
                console.log("get_pos")
                console.log(this.get_pos_hip_X, this.get_pos_hip_Y)
            }
            if (this.get_pos_flag) {
                // direction
                this.get_pos_dir_X = parseInt(e.offsetX);
                this.get_pos_dir_Y = parseInt(e.offsetY);
                this.get_dir_flag = true

                var x_ = this.get_pos_dir_X - this.base_x;
                var y_ = this.get_pos_dir_Y - this.base_y;
                this.rect_x = Math.sqrt(x_**2+y_**2)-this.tra_x;

                var rad_ = Math.atan(x_/y_);
                this.ang = parseInt(-rad_/Math.PI*180);
                if (y_>=0) {
                    this.ang = this.ang + 180
                }
                if (this.ang<0) {
                    this.ang = this.ang + 360
                }
                console.log("angle")
                console.log(this.ang)

                this.draw_arrow(1.0, this.ang, this.rect_x)
            }
            this.get_pos_flag = true;
        },
        no_hip() {
            this.get_pos_hip_X = parseInt(-1);
            this.get_pos_hip_Y = parseInt(-1);
            this.nano.ans.get_pos_hip_X = parseInt(-1);
            this.nano.ans.get_pos_hip_Y = parseInt(-1);
            let ctx = this.context.canvas.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, this.canvas_width, this.canvas_height)
            this.get_pos_flag = true;
        },
        unclear() {
            this.ang = -1
            this.get_pos_dir_X = parseInt(-1);
            this.get_pos_dir_Y = parseInt(-1);
            this.nano.ans.ang = -1
            this.nano.ans.get_pos_dir_X = parseInt(-1);
            this.nano.ans.get_pos_dir_Y = parseInt(-1);
            console.log("unclear")
            console.log(this.ang)
            this.submit()    
        },

        draw_arrow(trans, ang, rect_x) {
            let ctx = this.context.canvas.getContext("2d")   
            //真上が 0度なので、-90度する
            var ang_rad = (ang-90)/180*Math.PI;
            var base_len = Math.sqrt(this.base_x**2+this.base_y**2);
            var base_rad = Math.atan(this.base_y/this.base_x);
            var base_x_aft = base_len * Math.cos(base_rad - ang_rad);
            var base_y_aft = base_len * Math.sin(base_rad - ang_rad);
            
            ctx.rotate(ang_rad);
            ctx.beginPath();
            ctx.moveTo(base_x_aft,base_y_aft); //最初の点の場所
            ctx.lineTo(base_x_aft,base_y_aft-this.rect_y/2); //2番目の点の場所
            ctx.lineTo(base_x_aft+rect_x,base_y_aft-this.rect_y/2); 
            ctx.lineTo(base_x_aft+rect_x,base_y_aft-this.tra_y/2); 
            ctx.lineTo(base_x_aft+rect_x+this.tra_x,base_y_aft); 
            ctx.lineTo(base_x_aft+rect_x,base_y_aft+this.tra_y/2); 
            ctx.lineTo(base_x_aft+rect_x,base_y_aft+this.rect_y/2); 
            ctx.lineTo(base_x_aft,base_y_aft+this.rect_y/2); 
            ctx.closePath();    //最後の線 closeさせる
            
            if (trans==0.4) {
                ctx.strokeStyle = "rgb(255,0,0, 0.4)"; //枠線の色
                ctx.stroke();
                ctx.fillStyle = "rgba(255,0,0,0.4)";//塗りつぶしの色
            }
            if (trans==1.0) {
                ctx.strokeStyle = "rgb(255,0,0, 1.0)"; //枠線の色
                ctx.stroke();
                ctx.fillStyle = "rgba(255,0,0,1.0)";//塗りつぶしの色
            }
            ctx.fill();
            ctx.beginPath();
            ctx.arc(base_x_aft, base_y_aft, 15, 0, Math.PI * 2);
            ctx.fillStyle = "rgba(255,0,0,1.0)";
            ctx.fill();
            
            ctx.rotate(-ang_rad);
        },
        header_color(q1) {
            if (q1 && !this.get_pos_flag) {
                return "dark";
            }
            if (q1 && this.get_pos_flag) {
                return "light";
            }
            if (!q1 && !this.get_pos_flag) {
                return "light";
            }
            if (!q1 && this.get_pos_flag) {
                return "dark";
            }
        },
    },
    //画像を読み込んでから画像のサイズを取得
    watch: {
        new_image: function() {
            this.get_init_info()
        }
    }

};
</script>