<template>
    <div class="card">
        <div class="flex">
            <div class="img-wrapper"> 
                <img :src="nano.props.img_url0" /> 
            </div>
            <div id="container"> 
                <button @click="submitChoice('00')" id="item00"></button>
                <button @click="submitChoice('01')" id="item01"></button>
                <button @click="submitChoice('02')" id="item02"></button>
                <button @click="submitChoice('10')" id="item10"></button>
                <button @click="submitChoice('11')" id="item11"></button>
                <button @click="submitChoice('12')" id="item12"></button>
                <button @click="submitChoice('20')" id="item20"></button>
                <button @click="submitChoice('21')" id="item21"></button>
                <button @click="submitChoice('22')" id="item22"></button>

            </div>
            
        </div>
        

    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "https://images-na.ssl-images-amazon.com/images/I/61qEl7SAq9L._AC_SL1000_.jpg",
            "img_url1": "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQJxhbQz2Oy8Dn8ksxkaXPbzMIvhTaGUBH98P5nQ9zIXlQVV5OnWT1ozp9joA&usqp=CAc"
        }
    }),
    methods: {
        submitChoice(choice){
            this.nano.ans.choice = choice;
            this.submit();
        }
    }
};
</script>

<style scoped>
.card {
    width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
.flex {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}
.arrow {
    font-size: 50px;
    width: 100px;
    text-align: center;
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    min-width: 100px;
}
.button.same  { background-color: #060; }
.button.msame { background-color: #090; }
.button.mdiff { background-color: #c00; }
.button.diff  { background-color: #a00; }

.button.cannotannotate  { 
    background-color: #060; 
    float: left
    }

.subdiv {
    
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    
    cursor: pointer;
    
    position: absolute;
    
}
.area {
  padding: 0.1px;
  outline: 1px dashed green;
  height: 50px;
  width: 50px;
  text-align: center;
  float: left;
  margin: 5px;
  position: absolute;
}

#container {
    display: grid;
    grid-template-rows: 80px 80px 80px;
    grid-template-columns: 80px 80px 80px;
    position: absolute;
}


#item00 {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item01 {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item02 {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item10 {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item11 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item12 {
    grid-row: 2 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item20 {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item21 {
    grid-row: 3 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item22 {
    grid-row: 3 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

</style>
