<template>
    <div class="card">
        <div class="block">
            Worker ID (Please input your id into the below box)<span class="req">*</span><br /><br />
            <input type="text" v-nano.required v-model="name" />
        </div>
        <!--<div class="block">
            学年（小林小川研の学生以外は、所属）<span class="req">*</span><br /><br />
            <input type="text" v-nano.required v-model="grade" />
        </div>
        <div class="block">
            メールアドレス<span class="req">*</span><br /><br />
            <input type="text" v-nano.required v-model="email" />
        </div>
        <div class="block">
            電話番号<span class="req">*</span><br /><br />
            <input type="text" v-nano.required v-model="phone" />
        </div>-->
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        name: "",
        grade: "",
        email: "",
        phone: "",
    })
};
</script>

<style scoped>
span.req { color: #c00; }
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
