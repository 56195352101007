<template>
    <div class="card">
        <div class="block">
            何か伝えておきたいことなどあれば、ご自由にお書きください<br /><br />
            <textarea v-nano v-model="note"></textarea>
        </div>
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        note: "",
    })
};
</script>

<style scoped>
span.req { color: #c00; }
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
textarea {
    width: 100%;
    height: 150px;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
