<template>
    <div class="card">
        <div class="block">
            <b>Pre-survey.</b> Please answer the following questions first.
        </div>
        <div class="block">
            <b>Q1.</b> What is the name of this character?<br />
            <img src="https://pbs.twimg.com/profile_images/561277979855056896/4yRcS2Zo_400x400.png" width="100">
            <input type="text" v-nano.required v-model="characterName" />
        </div>
        <div class="block">
            <b>Q2.</b> Select all Japanese names that you know.<br />
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="Hayao Miyazaki" v-nano.required v-model="celebrities" />Hayao Miyazaki</div>
                <div class="check-item"><input type="checkbox" value="Pikotaro" v-nano.required v-model="celebrities" />Pikotaro</div>
                <div class="check-item"><input type="checkbox" value="Yoko Ono" v-nano.required v-model="celebrities" />Yoko Ono</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="Keisuke Honda" v-nano.required v-model="celebrities" />Keisuke Honda</div>
                <div class="check-item"><input type="checkbox" value="None of them" v-nano.required v-model="celebrities" />None of them</div>
            </div>
        </div>
        <div class="block">
            <b>Q3.</b> Are you an American citizen?<br />
            <div class="flex">
                <div class="check-item"><input type="radio" value="Yes" v-nano.required v-model="isAmerican" />Yes</div>
                <div class="check-item"><input type="radio" value="No" v-nano.required v-model="isAmerican" />No</div>
            </div>
        </div>
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        characterName: "",
        celebrities: [],
        isAmerican: ""
    })
};
</script>

<style scoped>
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
    justify-content: left;
    -webkit-align-items: center;
    align-items: center;
}
.check-item {
    width: 200px;
    padding: 10px;
}
.check-item>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
