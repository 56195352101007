<template>
    <div class="card">
        <h1 v-if="!turnNext">Click one tile including a cow's head.</h1>
        <h1 v-if="turnNext">Click one tile including a cow's tail.</h1>
        <div class="flex">
            <div class="img-wrapper"> 
                <img :src="nano.props.img_url0" /> 
            </div>
            <div id="container"> 
                <button @click="submitChoice('00')" id="item00"></button>
                <button @click="submitChoice('01')" id="item01"></button>
                <button @click="submitChoice('02')" id="item02"></button>
                <button @click="submitChoice('10')" id="item10"></button>
                <button @click="submitChoice('11')" id="item11"></button>
                <button @click="submitChoice('12')" id="item12"></button>
                <button @click="submitChoice('20')" id="item20"></button>
                <button @click="submitChoice('21')" id="item21"></button>
                <button @click="submitChoice('22')" id="item22"></button>

            </div>
            
        </div>
        <div class="check-item"><input v-on:click='showModal=!showModal' type="checkbox" value="irregular"  />Others</div>
        <div class="modal" v-if='showModal'>
            <div class="flex">
                <ul>
                Chose reasons, please. (You can chose one reason or many.)
                <div class="check-item-others"><input type="checkbox" value="too close" v-nano.required v-model="irregularCase" /> too close to a cow </div>
                <div class="check-item-others"><input type="checkbox" value="bad angle" v-nano.required v-model="irregularCase" /> bad camera angle </div>
                <div class="check-item-others"><input type="checkbox" value="unclear image" v-nano.required v-model="irregularCase" /> image is not clear </div>
                </ul>
            </div>
        </div>
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? (turnNext ? submit() : turnNext = true) : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "/static/sample-kubota-images/172137139645586-20181126040043_00_352_53_590_264.jpg",
            "img_url1": "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQJxhbQz2Oy8Dn8ksxkaXPbzMIvhTaGUBH98P5nQ9zIXlQVV5OnWT1ozp9joA&usqp=CAc"
        },
        turnNext: false,
        showModal: false,
        
        irregularCase: [],
    }),
    methods: {
        submitChoice(choice){
            if (!this.turnNext){
                this.nano.ans.head = choice;
                this.turnNext = true;
            } else {
                this.nano.ans.tail = choice;
                this.submit();
            }
        }
    }
};
</script>

<style scoped>
.card {
    width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
.flex {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
h1 {
    font-size: 22px;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}
.arrow {
    font-size: 50px;
    width: 100px;
    text-align: center;
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    min-width: 100px;
}
.button.same  { background-color: #060; }
.button.msame { background-color: #090; }
.button.mdiff { background-color: #c00; }
.button.diff  { background-color: #a00; }

.button.cannotannotate  { 
    background-color: #060; 
    float: left
    }

.subdiv {
    
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    
    cursor: pointer;
    
    position: absolute;
    
}
.area {
  padding: 0.1px;
  outline: 1px dashed green;
  height: 50px;
  width: 50px;
  text-align: center;
  float: left;
  margin: 5px;
  position: absolute;
}

#container {
    display: grid;
    grid-template-rows: 80px 80px 80px;
    grid-template-columns: 80px 80px 80px;
    position: absolute;
}


#item00 {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item01 {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item02 {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item10 {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item11 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item12 {
    grid-row: 2 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item20 {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item21 {
    grid-row: 3 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item22 {
    grid-row: 3 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.check-item {
    width: 200px;
    padding: 10px;
}
.check-item>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}

.modal-enter-active, .modal-leave-active {
  transition:opacity 0.5s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}
.check-item-others {
    width: 400px;
    padding: 5px;
}

</style>
