<template>
<div>
    <div class="card">
        <h1>Classify cow's postures, "Sitting" or "Standing", please.</h1>
        <h1>Here are three samples. These shows three different cases, "Sitting", "Standing", and "Others".</h1>
        <h1>There are 200 questions in total. It takes 5 - 10 minutes.</h1>
    </div>
    <div class="card">
        <h1>This is a cow's "Sitting" case.</h1>
        <div class="flex">
            <div class="img-wrapper"> <img :src="nano.props.img_url0" /> </div>
        </div>
        <div class="flex">
            <div class="arrow">
                <h2>click</h2>
                &#x27a1;
            </div>
            <button :disabled="true" class="button Sitting">Sitting</button>
            <button :disabled="true" class="button Standing">Standing</button>
            <div class="check-item"><input :disabled="true" type="checkbox" />Others</div>
        </div>
    </div>
    <div class="card">
        <h1>This is a cow's "Standing" case.</h1>
        <div class="flex">
            <div class="img-wrapper"> <img :src="nano.props.img_url1" /> </div>
        </div>
        <div class="flex">
            <button :disabled="true" class="button Sitting">Sitting</button>
            <div class="arrow">
                <h2>click</h2>
                &#x27a1;
            </div>
            <button :disabled="true" class="button Standing">Standing</button>
            <div class="check-item"><input :disabled="true" type="checkbox" />Others</div>
            <p></p>
        </div>
    </div>
    <div class="card">
        <h1>This is an "Others" case.</h1>
        <div class="flex">
            <div class="img-wrapper"> <img :src="nano.props.img_url2" /> </div>
        </div>
        <div class="flex">
            <button :disabled="true" class="button Sitting">Sitting</button>
            <button :disabled="true" class="button Standing">Standing</button>
            <div class="arrow">
                <h2>1.check</h2>
                &#x27a1;
            </div>
            <div class="check-item"><input :disabled="true" type="checkbox" checked />Others</div>
        </div>
        <div class="flex">
            <div class="arrow">
                <h2>2.check</h2>
                    &#x27a1;
            </div>
            <ul>
            Chose reasons, please. (You can chose one reason or many.)
            <div class="check-item-others"><input type="checkbox" :disabled="true" checked /> too close to a cow </div>
            <div class="check-item-others"><input type="checkbox" :disabled="true" /> bad camera angle </div>
            <div class="check-item-others"><input type="checkbox" :disabled="true" /> image is not clear </div>
            </ul>
        </div>
        <div class="next-btn-wrapper">
            <div class="arrow">
                <h2>3.click</h2>
                    &#x27a1;
            </div>
            <button :disabled="true" >NEXT</button>
        </div>
    </div>
</div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "/static/sample-kubota-images/172137139645586-20190511120554_0_381-76-123-166.jpg",
            "img_url1": "/static/sample-kubota-images/172137139645586-20181126040043_00_352_53_590_264.jpg",
            "img_url2": "/static/sample-kubota-images/172137139645586-20190315144106_0_346-171-225-293.jpg",

        }
    }),
    
};
</script>


<style scoped>

span.caution { color: #c00; }

.card {
    width: 900px;
    padding: 20px;
    box-sizing: border-box;
    margin: 50px auto;
    border: 1px solid black;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}

h1 {
    font-size: 18px;
}
h2 {
    font-size: 16px;
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    color: white;
    font-size: 18px;
    min-width: 100px;
}
.check-item {
    width: 200px;
    padding: 10px;
}
.check-item-others {
    width: 400px;
    padding: 5px;
}
.check-item>input {
    margin-right: 5px;
}
.button.Sitting  { background-color: #060; }
.button.Standing { background-color: #a00; }
.arrow {
    font-size: 50px;
    width: 100px;
    text-align: center;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
    position: relative;
    bottom: 100px;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
div.next-btn-wrapper>div.arrow {
    width: 100px;
    padding: 10px;
    position: relative;
    left: 650px;
    bottom: -75px;
}
</style>