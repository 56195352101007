<template>
    <div class="card">
        <div class="flex">
            <div class="img-wrapper"> <img :src="nano.props.img_url0" /> </div>
            <!--<div class="arrow">&#11020;</div>
            <div class="img-wrapper"> <img :src="nano.props.img_url1" /> </div>-->
        </div>
        <div class="flex">
            <button @click="submitChoice('Sitting')" class="button same">Sitting</button>
            <button @click="submitChoice('Maybe Sitting')" class="button msame">Maybe Sitting</button>
            <button @click="submitChoice('Maybe Standing')" class="button mdiff">Maybe Standing</button>
            <button @click="submitChoice('Standing')" class="button diff">Standing</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "https://images-na.ssl-images-amazon.com/images/I/61qEl7SAq9L._AC_SL1000_.jpg",
            "img_url1": "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQJxhbQz2Oy8Dn8ksxkaXPbzMIvhTaGUBH98P5nQ9zIXlQVV5OnWT1ozp9joA&usqp=CAc"
        }
    }),
    methods: {
        submitChoice(choice){
            this.nano.ans.choice = choice;
            this.submit();
        }
    }
};
</script>

<style scoped>
.card {
    width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}
.arrow {
    font-size: 50px;
    width: 100px;
    text-align: center;
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    color: white;
    font-size: 18px;
    min-width: 100px;
}
.button.same  { background-color: #060; }
.button.msame { background-color: #090; }
.button.mdiff { background-color: #c00; }
.button.diff  { background-color: #a00; }
</style>
