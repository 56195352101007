<template>
<div>
    <div class="card">
        <h1>This task is labeling a cow's head and tail.</h1>
        <h1>First, click one tile including a cow's head.</h1>
        <h1>Next, click one tile including a cow's tail</h1> 
        <h1>and continue the same task using another image.</h1>
    </div>
    <div class="card">
        <h1>Click one tile including a cow's head.</h1>
         <div class="flex">
            <div class="img-wrapper"> 
                <img :src="nano.props.img_url0" /> 
            </div>
            <div id="container"> 
                <button id="item00"></button>
                <button id="item01"></button>
                <button id="item02"></button>
                <button id="item10"></button>
                <button id="item11"></button>
                <button id="item12"></button>
                <button id="item20"></button>
                <button id="item21"></button>
                <button id="item22"></button>
            </div>
            <div class="arrow1">
                <h2>Click</h2>
                    &#x2b05;
            </div>
        </div>
    </div>
    <div class="card">
        <h1>Click one tile including a cow's tail.</h1>
         <div class="flex">
            <div class="img-wrapper"> 
                <img :src="nano.props.img_url0" /> 
            </div>
            <div id="container"> 
                <button id="item00"></button>
                <button id="item01"></button>
                <button id="item02"></button>
                <button id="item10"></button>
                <button id="item11"></button>
                <button id="item12"></button>
                <button id="item20"></button>
                <button id="item21"></button>
                <button id="item22"></button>
            </div>
            <div class="arrow2">
                <h2>Click</h2>
                    &#x27a1;
            </div>
        </div>
    </div>
</div>
</template>



<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "/static/sample-kubota-images/172137139645586-20181126040043_00_352_53_590_264.jpg",
        }
    }),
    
};
</script>


<style scoped>
.card {
    width: 950px;
    padding: 20px;
    box-sizing: border-box;
    margin: 50px auto;
    border: 1px solid black;
}
.flex {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}
h1 {
    font-size: 22px;
}
h2 {
    font-size: 16px;
    
}
.arrow1 {
    font-size: 60px;
    width: 100px;
    text-align: center;
    position: absolute;
    left: 560px;
    bottom: 140px;
    
}
.arrow2 {
    font-size: 60px;
    width: 100px;
    text-align: center;
    position: absolute;
    left: 250px;
    bottom: 220px;
    
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    min-width: 100px;
}
.button.same  { background-color: #060; }
.button.msame { background-color: #090; }
.button.mdiff { background-color: #c00; }
.button.diff  { background-color: #a00; }

.button.cannotannotate  { 
    background-color: #060; 
    float: left
    }

.subdiv {
    
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    
    cursor: pointer;
    
    position: absolute;
    
}
.area {
  padding: 0.1px;
  outline: 1px dashed green;
  height: 50px;
  width: 50px;
  text-align: center;
  float: left;
  margin: 5px;
  position: absolute;
}

#container {
    display: grid;
    grid-template-rows: 80px 80px 80px;
    grid-template-columns: 80px 80px 80px;
    position: absolute;
}


#item00 {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item01 {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item02 {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item10 {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item11 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item12 {
    grid-row: 2 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

#item20 {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
    outline: 2px dashed green;
    
}

#item21 {
    grid-row: 3 / 3;
    grid-column: 2 / 3;
    outline: 2px dashed green;
    
}

#item22 {
    grid-row: 3 / 3;
    grid-column: 3 / 3;
    outline: 2px dashed green;
    
}

</style>
