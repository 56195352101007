<template>
    <div class="card">
        <div class="flex">
            <div class="img-wrapper"> <img :src="nano.props.img_url0" /> </div>
        </div>
        <div class="flex">
            <button @click="submitChoice('Sitting')" class="button Sitting">Sitting</button>
            <button @click="submitChoice('Standing')" class="button Standing">Standing</button>
            <div class="check-item"><input v-on:click='showModal=!showModal' type="checkbox" value="irregular"  />Others</div>
        </div>
        <div class="modal" v-if='showModal'>
            <div class="flex">
                <ul>
                Chose reasons, please. (You can chose one reason or many.)
                <div class="check-item-others"><input type="checkbox" value="too close" v-nano.required v-model="irregularCase" /> too close to a cow </div>
                <div class="check-item-others"><input type="checkbox" value="bad angle" v-nano.required v-model="irregularCase" /> bad camera angle </div>
                <div class="check-item-others"><input type="checkbox" value="unclear image" v-nano.required v-model="irregularCase" /> image is not clear </div>
                </ul>
            </div>
        </div>
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            "img_url0": "/static/sample-kubota-images/172137139645586-20181126040043_00_352_53_590_264.jpg",
            "img_url1": "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQJxhbQz2Oy8Dn8ksxkaXPbzMIvhTaGUBH98P5nQ9zIXlQVV5OnWT1ozp9joA&usqp=CAc"
        },
        
        showModal: false,
        
        irregularCase: [],
    }),
    methods: {
        submitChoice(choice){
            this.nano.ans.choice = choice;
            this.submit();
        },
        click01: function (ev) {
            this.showModal = false
            ev.preventDefault()
        }
    }
};
</script>

<style scoped>
.card {
    width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.img-wrapper {
    height: 300px;
    width: 400px;
    padding: 20px;
    margin: 30px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 3px;
    text-align: center;
}
.img-wrapper>img {
    height: 100%;
}
.arrow {
    font-size: 50px;
    width: 100px;
    text-align: center;
}
.button {
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    border-radius: 3px;
    color: white;
    font-size: 18px;
    min-width: 100px;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.check-item {
    width: 200px;
    padding: 10px;
}
.check-item>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}

.modal-enter-active, .modal-leave-active {
  transition:opacity 0.5s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}
.check-item-others {
    width: 400px;
    padding: 5px;
}

.button.Sitting  { background-color: #060; }
.button.Standing { background-color: #a00; }
.button.mdiff { background-color: #c00; }
.button.diff  { background-color: #090; }
</style>
