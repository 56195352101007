<template>
    <div class="card">
        <div class="block">
            使用PCのOSとバージョン<span class="req">*</span><br /><br />
            <input type="text" v-nano.required v-model="osversion" />
        </div>
        <div class="block">
            経験のある技術（当てはまるものを全て選択）<span class="req">*</span><br /><br />
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="HTML / CSS" v-nano.required v-model="technologies" />HTML / CSS</div>
                <div class="check-item"><input type="checkbox" value="JavaScript" v-nano.required v-model="technologies" />JavaScript</div>
                <div class="check-item"><input type="checkbox" value="Python" v-nano.required v-model="technologies" />Python</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="Vue.js" v-nano.required v-model="technologies" />Vue.js</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="非同期プログラミング（async, await, promise, etc.）" v-nano.required v-model="technologies" />非同期プログラミング（async, await, promise, etc.）</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="SSH（リモートログイン）" v-nano.required v-model="technologies" />SSH（リモートログイン）</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="コンソールベースのエディタ（vim, emacsなど）" v-nano.required v-model="technologies" />コンソールベースのエディタ（vim, emacsなど）</div>
                <div class="check-item"><input type="checkbox" value="Redis" v-nano.required v-model="technologies" />Redis</div>
            </div>
            <div class="flex">
                <div class="check-item"><input type="checkbox" value="Docker" v-nano.required v-model="technologies" />Docker</div>
                <div class="check-item"><input type="checkbox" value="Docker Compose" v-nano.required v-model="technologies" />Docker Compose</div>
                <div class="check-item"><input type="checkbox" value="上記に該当なし" v-nano.required v-model="technologies" />上記に該当なし</div>
            </div>
        </div>
        <div class="block">
            当日の参加方法（予定で結構です；併用して開催します）<span class="req">*</span><br /><br />
            <div class="check-item"><input type="radio" value="GCS（対面）" v-nano.required v-model="location" />GCS（対面）</div>
            <div class="check-item"><input type="radio" value="自宅（zoom）" v-nano.required v-model="location" />自宅（zoom）</div>
        </div>
        <div class="block">
            <span style="font-size:0.8em;color:#999;">※GCS参加者間では会議室（306）を使用します。各参加者距離をとって着席の上、必要な水分補給以外の目的での飲食は原則禁止とします。もし感染症対策上の懸念・質問がある場合は、次のページの記述欄にて教えて
            下さい。</span>
        </div>
        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        osversion: "",
        technologies: [],
        location: ""
    })
};
</script>

<style scoped>
span.req { color: #c00; }
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
    justify-content: left;
    -webkit-align-items: center;
    align-items: center;
}
.check-item {
    min-width: 200px;
    padding: 10px;
}
.check-item>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
